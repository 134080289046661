import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from '~/common/components';
import { formatDayMonthYear } from '~/common/utils';
import { useInitData } from '../hooks';
const CustomerTags = ({ tagIds }) => {
    const init = useInitData();
    const tags = tagIds.map((tagId) => init.filter_options.customer_type.find((type) => type.value === tagId));
    return (_jsx("div", { className: "flex items-center h-[18px] gap-[4px] absolute top-[38px]", children: tags.map(({ value, color, name }) => (_jsx(Badge, { size: "small", color: color, children: name }, value))) }));
};
export const tableConfig = {
    columns: [
        { key: 'id', name: 'ID', width: '96px' },
        {
            key: 'name',
            name: 'Name',
            width: 'minmax(144px, 2fr)',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: "truncate", children: cellData }), _jsx(CustomerTags, { tagIds: rowData.type })] })),
            getClassName: () => 'relative max-w-full',
            noTruncate: true,
        },
        {
            key: 'email',
            name: 'Email',
            width: 'minmax(144px, 2fr)',
        },
        {
            key: 'company',
            name: 'Company',
            width: 'minmax(144px, 1fr)',
        },
        {
            key: 'role',
            name: 'Role',
            width: 'minmax(100px, 1fr)',
        },
        {
            key: 'signed_up',
            name: 'Signed up',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => formatDayMonthYear(cellData),
        },
        {
            key: 'last_update',
            name: 'Last update',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => (cellData ? formatDayMonthYear(cellData) : '—'),
        },
    ],
    sortable: ['id', 'signed_up', 'last_update'],
    getCellClassName: () => 'h-8 pt-[14px]',
};

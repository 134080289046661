import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBell, faEnvelope, faLock, faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { differenceInHours } from 'date-fns';
import { memo } from 'react';
import { CircularProgress, Copyable, DateDistance, IconBox, Link, Tags, UsersGroup, } from '~/common/components';
import { addSlug, cx, getPercentage, pluralize } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { iterationToColor } from '~/orders/utils';
import { useCurrentUserData } from '~/root';
const tagClassName = 'flex items-center justify-center w-fit px-1 border rounded-[4px] def:border-greyscale-200 h-3 bg-greyscale-0';
const OrderCollaboration = (props) => {
    if (!props.collaboration.checklist_items_total &&
        !props.collaboration.unread_comments_count &&
        !props.notifications) {
        return null;
    }
    return (_jsxs("div", { className: "flex items-center gap-[6px]", children: [!!props.collaboration.checklist_items_total && (_jsxs("div", { "data-tt": "Check list progress", "data-tt-placement": "bottom", className: tagClassName, children: [_jsx(CircularProgress, { percentage: getPercentage(props.collaboration.checklist_items_done, props.collaboration.checklist_items_total), size: 14, color: "#6D7395", strokeWidth: 2 }), _jsxs("span", { className: "font-brand-t5 text-greyscale-500 ml-[4px]", children: [props.collaboration.checklist_items_done, "/", props.collaboration.checklist_items_total] })] })), !!props.notifications && (_jsx("div", { "data-tt": `${props.notifications} new notification${pluralize(props.notifications)}`, "data-tt-placement": "bottom", className: tagClassName, children: _jsxs("div", { className: "relative", children: [_jsx(IconBox, { size: "s", icon: faEnvelope, className: "text-greyscale-500" }), _jsx("div", { className: cx('absolute right-[-2px] top-0 h-[6px] w-[6px] rounded-full pointer-events-none', 'bg-primary-300 border border-solid border-greyscale-0') })] }) })), !!props.collaboration.unread_comments_count && (_jsx("div", { "data-tt": "New message in team space", "data-tt-placement": "bottom", className: tagClassName, children: _jsxs("div", { className: "relative", children: [_jsx(IconBox, { size: "s", icon: faBell, className: "text-greyscale-500" }), _jsx("div", { className: cx('absolute right-[1px] top-0 h-[6px] w-[6px] rounded-full pointer-events-none', 'bg-danger-400 border border-solid border-greyscale-0') })] }) }))] }));
};
const Revision = ({ revision }) => (_jsxs("div", { className: "flex items-center gap-1 mt-1", children: [_jsx("div", { className: cx('h-[6px] w-[6px] rounded-full', iterationToColor[revision] || 'bg-greyscale-525') }), _jsx("div", { className: "font-brand-c1r text-greyscale-500", children: revision ? `Revision ${revision}` : 'First draft' })] }));
const HOURS_DIFF_CRITICAL = 3;
const HOURS_DIFF_WARNING = 24;
const getDeadlineClassName = (deadline) => {
    const diffHours = differenceInHours(deadline, new Date());
    if (diffHours <= HOURS_DIFF_CRITICAL) {
        return {
            text: 'text-danger-400',
            bg: 'bg-danger-50',
        };
    }
    if (diffHours <= HOURS_DIFF_WARNING) {
        return {
            text: 'text-warning-500',
            bg: 'bg-warning-50',
        };
    }
    return {
        text: 'text-greyscale-500',
        bg: 'bg-greyscale-100',
    };
};
export const BoardItem = memo((props) => {
    const user = useCurrentUserData();
    const deadlineClassName = getDeadlineClassName(props.deadline);
    const [, slides, customerName] = props.title.split(' | ');
    const slug = `-${slides}${addSlug(customerName)}`;
    return (_jsxs(Link.Base, { to: `${ORDERS_URL}/${props.id}${slug}${user.role === 'designer' ? '#team-space' : ''}`, className: "px-[12px] py-1 bg-greyscale-0 flex flex-col rounded-[4px] cursor-pointer relative focus-visible:outline-offset-0 hover:bg-greyscale-50 transition-brand", newTab: true, children: [_jsx(Tags, { tagSize: "small", labels: props.tags }), _jsx(Copyable, { className: "font-brand-t4m mt-1", text: props.title, children: props.is_confidential && (_jsx(IconBox, { icon: faLock, size: "s", className: "text-greyscale-500", "data-tt": "This order is restricted" })) }), _jsx(Revision, { revision: props.revision }), !user.temp_manager && user.role === 'designer' ? (_jsxs("div", { className: "flex items-center gap-[6px] mt-[12px]", children: [_jsx(OrderCollaboration, { ...props }), !!props.designers.length && (_jsx("div", { className: "ml-auto", onClick: (e) => e.preventDefault(), children: _jsx(UsersGroup, { users: props.designers }) }))] })) : (_jsxs("div", { className: "mt-2", children: [_jsx(OrderCollaboration, { ...props }), _jsx("div", { className: "h-[1px] bg-greyscale-200 -mx-[12px] my-1" }), _jsxs("div", { className: "flex items-center", children: [_jsxs("div", { className: cx('flex items-center justify-center w-fit px-1 gap-[4px]', deadlineClassName.bg), children: [_jsx(IconBox, { icon: faStopwatch, className: deadlineClassName.text }), _jsx(DateDistance, { to: props.deadline, className: cx(deadlineClassName.text, 'font-brand-t5') })] }), !!props.designers.length && (_jsx("div", { className: "ml-auto", onClick: (e) => e.preventDefault(), children: _jsx(UsersGroup, { users: props.designers }) }))] })] }))] }));
});

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useVisited } from '~/root';
import { Order, OrderSync, Revisions } from './Order';
import { BoardView, ListView } from './Orders';
import { ReviewsList } from './Reviews';
export const ORDERS_URL = '/orders';
const Orders = () => {
    const { path } = useRouteMatch();
    useVisited('orders');
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: path, component: ListView }), _jsx(Route, { exact: true, path: `${path}/board`, component: BoardView }), _jsx(Route, { exact: true, path: `${path}/reviews/:id`, component: ReviewsList }), _jsx(Route, { exact: true, path: `${path}/reviews`, component: ReviewsList }), _jsx(Route, { path: `${path}/:id(\\d+.*?)`, children: _jsx(OrderSync, { children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: `${path}/:id(\\d+-?[^/]*?)`, component: Order }), _jsx(Route, { exact: true, path: `${path}/:id(\\d+-?[^/]*?)/revisions`, component: Revisions })] }) }) })] }));
};
const nav = {
    icon: faShapes,
    items: [
        {
            path: ORDERS_URL,
            label: 'All orders',
            isActive: (pathname) => pathname.includes('orders') && !pathname.includes('reviews'),
        },
        {
            path: `${ORDERS_URL}/reviews`,
            label: 'Reviews',
        },
    ],
    mainPath: ORDERS_URL,
    path: ORDERS_URL,
    label: 'Orders',
};
export const ordersModule = {
    canShow: (permissions) => permissions.ordersView,
    route: {
        path: ORDERS_URL,
        component: Orders,
    },
    nav,
};

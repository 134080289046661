import { jsx as _jsx } from "react/jsx-runtime";
import { CountryFlagLabel } from '~/common/components';
import { date } from '~/common/utils';
const basicUserColumns = [
    {
        key: 'id',
        name: 'ID',
        width: '72px',
    },
    { key: 'name', name: 'Name', width: 'minmax(200px, 3fr)' },
    { key: 'job_title', name: 'Job title', width: 'minmax(144px, 2fr)' },
    {
        key: 'office',
        name: 'Office',
        cellRenderer: ({ cellData }) => cellData && (_jsx(CountryFlagLabel, { country: cellData.country, countryCode: cellData.country_code })),
        width: 'minmax(120px, 1fr)',
    },
    {
        key: 'updated',
        name: 'Last updated',
        cellRenderer: ({ cellData }) => date.formatDateShort(cellData),
        width: '144px',
    },
];
const basicSortableColumns = ['id', 'name', 'updated'];
const extendFromBasic = (columns = [], sortable = null) => {
    return {
        columns: [
            ...basicUserColumns.slice(0, -1),
            ...columns,
            basicUserColumns[basicUserColumns.length - 1],
        ],
        sortable: sortable
            ? [...new Set([...sortable, ...basicSortableColumns])]
            : basicSortableColumns,
    };
};
// I don't know how to win if this is not Any
// current situation is ok, because everything is typechecked here
// The only thing that can't be fixed is invocation of this function in Users
export const getTableConfigFromRole = (role) => {
    switch (role) {
        case 'designer':
            return extendFromBasic([
                { key: 'capacity', name: 'Design Capacity', width: '160px' },
                {
                    key: 'team',
                    name: 'Design Team',
                    cellRenderer: ({ cellData }) => (cellData === null || cellData === void 0 ? void 0 : cellData.name) || '—',
                    width: 'minmax(144px, 2fr)',
                },
            ], ['capacity']);
        default:
            return extendFromBasic();
    }
};

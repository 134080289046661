import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CountryFlags from 'country-flag-icons/react/3x2';
import { forwardRef } from 'react';
import { cx } from '~/common/utils';
const getBorderSize = (size) => {
    if (size >= 32 && size < 64)
        return 2;
    if (size >= 80)
        return 3;
    return 1;
};
export const CountryFlag = forwardRef(({ countryCode, className, size = 16, ...props }, ref) => {
    const Component = CountryFlags[countryCode];
    const borderWidth = getBorderSize(size);
    return (_jsx("div", { ref: ref, ...props, style: { width: size, height: size, borderWidth, minWidth: size, minHeight: size }, className: cx('relative border-solid border-greyscale-300 rounded-full overflow-hidden', className), children: Component !== undefined && (_jsx(Component, { className: "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2", style: { width: size * 1.5 - 2, height: size - 2 } })) }));
});
export const CountryFlagLabel = ({ country, countryCode, size = 16 }) => (_jsxs("div", { className: "flex items-center", children: [_jsx(CountryFlag, { size: size, countryCode: countryCode }), _jsx("span", { className: "ml-1 truncate", children: country })] }));

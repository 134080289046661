import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSortCircle, faSortCircleDown, faSortCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useEffect } from 'react';
import { IconBox, LayoutContainer, Link, Pagination } from '~/common/components';
import { cx } from '~/common/utils';
import css from './Table.module.scss';
export const Cell = ({ className, outerClassName, noTruncate, children }) => (_jsx("div", { className: "group-hover:bg-greyscale-75/50 group/cell", children: _jsx("div", { className: cx('group-first-of-type/cell:pl-3', outerClassName, css.cell), children: _jsx("div", { className: cx(className, !noTruncate && 'truncate'), children: children !== null && children !== void 0 ? children : '—' }) }) }));
export const Table = ({ tableConfig, items, totalPages, page, onPageChange, sort, onSort, className, containerClassName, onRowClick, isFetching, noDataPlaceholder, linkGetter, }) => {
    const mapTableHead = ({ key, name }) => {
        const sorted = key === (sort === null || sort === void 0 ? void 0 : sort.option);
        const sortable = tableConfig.sortable.includes(key);
        const isSortedAsc = sorted && sort.order === 'asc';
        const handleSort = () => sortable && onSort(key);
        return (_jsx("div", { className: css.th, children: _jsxs("div", { className: cx(css.thTextContainer, sortable && css.sortable, sorted && css.sorted), onClick: handleSort, children: [_jsx("span", { children: name }), sortable && (_jsx(IconBox, { size: "s", className: css.icon, icon: sorted ? (isSortedAsc ? faSortCircleUp : faSortCircleDown) : faSortCircle }))] }) }, key));
    };
    const TableHead = tableConfig.columns.map(mapTableHead);
    const makeBodyRow = (rowData) => {
        const makeColumnCell = (column) => {
            var _a, _b, _c, _d;
            const cellData = rowData[column.key];
            const params = { cellData, rowData };
            return (_jsx(Cell, { className: cx((_a = column.getClassName) === null || _a === void 0 ? void 0 : _a.call(column, params), (_b = tableConfig.getCellClassName) === null || _b === void 0 ? void 0 : _b.call(tableConfig, rowData)), outerClassName: (_c = tableConfig.getOuterCellClassName) === null || _c === void 0 ? void 0 : _c.call(tableConfig, rowData), noTruncate: column.noTruncate, children: column.cellRenderer ? column.cellRenderer(params) : (_d = cellData) !== null && _d !== void 0 ? _d : '—' }, rowData.id + column.key));
        };
        if (linkGetter) {
            return (_jsxs(Link.Base, { to: linkGetter(rowData), className: "group contents cursor-pointer", children: [tableConfig.columns.map(makeColumnCell), _jsx("div", { className: "h-0 col-span-full relative grid place-items-center", children: _jsx("div", { className: "transition-all absolute bottom-[4px]", children: _jsx(Link.Button, { color: "grey", to: linkGetter(rowData), className: "transition-opacity opacity-0 bg-greyscale-0 select-none focus-visible:opacity-100 w-0 h-0 z-[-1] focus-visible:z-10 focus-visible:w-fit focus-visible:h-4", "aria-label": `Go to ${rowData.id}`, children: "Go to" }) }) })] }, rowData.id));
        }
        return (
        //Here it's a span in order for :last-of-type selector to properly work on header items
        _jsx("span", { className: "group contents", onClick: () => onRowClick === null || onRowClick === void 0 ? void 0 : onRowClick(rowData), children: tableConfig.columns.map(makeColumnCell) }, rowData.id));
    };
    const TableBody = items.map(makeBodyRow);
    const scrollToTop = useCallback(() => {
        if (!tableConfig.disableScrollOnPageSwitch) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [tableConfig.disableScrollOnPageSwitch]);
    useEffect(() => {
        if (isFetching === false) {
            scrollToTop();
        }
    }, [isFetching, scrollToTop]);
    const handlePageChange = (page) => {
        if (isFetching === undefined) {
            scrollToTop();
        }
        onPageChange(page);
    };
    if (!items.length && noDataPlaceholder) {
        return _jsx(_Fragment, { children: noDataPlaceholder });
    }
    const gridTemplateColumns = tableConfig.columns
        .map((item) => item.width || 'minmax(0, 1fr)')
        .join(' ');
    return (_jsx(LayoutContainer, { className: cx(containerClassName), children: _jsxs("div", { className: "relative h-full overflow-hidden", children: [_jsx("div", { className: cx('border border-greyscale-200 overflow-auto rounded-[4px]', totalPages > 1 ? 'max-h-[calc(100%-48px)]' : 'max-h-full'), children: _jsxs("div", { className: cx(css.table, className), style: { gridTemplateColumns }, children: [TableHead, TableBody] }) }), totalPages > 1 && (_jsx(Pagination, { className: "pt-2 z-10 bg-greyscale-0", page: page, pageAmount: totalPages, onClick: handlePageChange }))] }) }));
};

import { jsx as _jsx } from "react/jsx-runtime";
import { CountryFlagLabel } from '~/common/components';
export const tableConfig = {
    columns: [
        { key: 'id', name: 'ID', width: '72px' },
        {
            key: 'name',
            name: 'Name',
            width: 'minmax(200px, 2fr)',
        },
        { key: 'description', name: 'Description', width: 'minmax(200px, 3fr)' },
        { key: 'total_members', name: 'Total members', width: 'minmax(144px, 2fr)' },
        {
            key: 'office',
            name: 'Office',
            cellRenderer: ({ cellData }) => (_jsx(CountryFlagLabel, { country: cellData.country, countryCode: cellData.country_code })),
            width: 'minmax(144px, 1fr)',
        },
    ],
    sortable: ['name', 'description', 'total_members'],
};

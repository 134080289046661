import { jsx as _jsx } from "react/jsx-runtime";
import { formatTimeAtDay } from '~/common/utils';
import { Action } from './Action';
import { Actor } from './Actor';
const defaultTableColumns = [
    {
        key: 'date',
        name: 'Date',
        width: 'minmax(200px, 1fr)',
        cellRenderer: ({ cellData }) => _jsx("div", { className: "pt-[6px]", children: formatTimeAtDay(cellData) }),
    },
    {
        key: 'action',
        name: 'Action',
        cellRenderer: ({ cellData }) => cellData && _jsx(Action, { action: cellData }),
        width: 'minmax(200px, 2fr)',
    },
    {
        key: 'actor',
        name: 'Actor',
        width: 'minmax(200px, 1fr)',
        cellRenderer: ({ cellData }) => _jsx(Actor, { actor: cellData }),
    },
];
export const defaultTableConfig = {
    columns: defaultTableColumns,
    sortable: [],
    getCellClassName: () => 'py-[10px]',
};

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge } from '~/common/components';
import { formatDayMonthYear, formatMonthToYearsAndMonth } from '~/common/utils';
export const tableConfig = {
    columns: [
        { key: 'id', name: 'ID', width: '72px' },
        {
            key: 'customer_name',
            name: 'Customer name',
            width: 'minmax(120px, 2fr)',
        },
        {
            key: 'company_name',
            name: 'Company',
            width: 'minmax(120px, 2fr)',
        },
        {
            key: 'products',
            name: 'Products',
            width: 'minmax(190px, 1fr)',
            getClassName: () => 'flex gap-1',
            cellRenderer: ({ cellData }) => _jsx(Products, { products: cellData }),
        },
        {
            key: 'monthly_cost_usd',
            name: 'Monthly cost',
            width: 'minmax(112px, 1fr)',
            cellRenderer: ({ rowData }) => rowData.monthly_cost,
        },
        {
            key: 'status',
            name: 'Status',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => cellData && _jsx(Badge, { color: cellData.color, children: cellData.name }),
        },
        {
            key: 'renews_at',
            name: 'Next payment',
            width: 'minmax(121px, 1fr)',
            cellRenderer: ({ cellData }) => cellData && formatDayMonthYear(cellData),
        },
        {
            key: 'renewal_period',
            name: 'Billing cycle',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => formatMonthToYearsAndMonth(cellData),
        },
        {
            key: 'updated_at',
            name: 'Last Update',
            width: 'minmax(150px, 1fr)',
            cellRenderer: ({ cellData }) => formatDayMonthYear(cellData),
        },
    ],
    sortable: ['monthly_cost_usd', 'renews_at', 'renewal_period', 'updated_at'],
    getOuterCellClassName: () => 'h-8',
};
export const Products = ({ products }) => {
    return (_jsx(_Fragment, { children: products.map(({ name, value }) => (_jsx(Badge, { color: "grey", children: name }, value))) }));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Link } from '~/common/components';
import { formatNumber, formatDateShort } from '~/common/utils';
const getColumns = (minified) => [
    { key: 'id', name: 'ID', width: '72px' },
    {
        key: 'description',
        name: 'Description',
        width: 'minmax(250px, 3fr)',
    },
    {
        key: 'created_at',
        name: 'Date',
        cellRenderer: ({ cellData }) => formatDateShort(cellData),
        width: 'minmax(110px, 1fr)',
    },
    !minified && {
        key: 'payment_method',
        name: 'Payment Method',
        cellRenderer: ({ cellData }) => cellData.name.replace('-', '  '),
        width: 'minmax(130px, 1fr)',
    },
    {
        key: 'status',
        name: 'Status',
        cellRenderer: ({ cellData }) => _jsx(Badge, { color: cellData.color, children: cellData.name }),
        width: 'minmax(100px, 1fr)',
    },
    {
        key: 'amount',
        name: 'Amount',
        width: 'minmax(84px, 1fr)',
    },
    !minified && {
        key: 'credits',
        name: 'Credits',
        cellRenderer: ({ cellData }) => formatNumber(cellData),
        width: 'minmax(80px, 1fr)',
    },
    !minified && {
        key: 'credit_balance',
        name: 'Credit balance',
        width: 'minmax(120px, 1fr)',
    },
    {
        key: 'invoice_url',
        name: 'Invoice number',
        width: 'minmax(128px, 1fr)',
        getClassName: () => 'flex justify-center',
        cellRenderer: ({ cellData, rowData }) => cellData && (_jsxs(_Fragment, { children: [rowData.invoice_number && _jsx("span", { className: "mr-1", children: rowData.invoice_number }), _jsx(Link.Icon, { size: "m", icon: faDownToLine, href: cellData, newTab: true })] })),
    },
].filter(Boolean);
const sortableColumns = ['id', 'created_at', 'amount'];
export const getTableConfig = (minified) => ({
    columns: getColumns(minified),
    sortable: minified ? [] : sortableColumns,
});

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { Badge, Button, DeleteShell, Menu, useModalContext } from '~/common/components';
import { addQueryParams, formatDateDistance, formatDayMonthYear } from '~/common/utils';
import { useCancelInvitation, useDeleteTeamMembers, useResendInvitation } from '~/customers/hooks';
import { ORDERS_URL } from '~/orders';
import { useCurrentUserData } from '~/root';
import { LoginAsCustomer } from '../LoginAsCustomer';
import { EditTeamMember } from './EditTeamMember';
const DeleteMember = ({ memberId, onClose }) => {
    const { mutateAsync, isLoading } = useDeleteTeamMembers();
    const handleDelete = () => {
        mutateAsync(memberId).then(onClose);
    };
    return (_jsx(DeleteShell, { className: "w-[608px]", loading: isLoading, heading: "Delete the customer?", description: "You will still see all the actions this customer has done", submitText: "Yes, delete customer", cancelText: "No, keep customer", onSubmit: handleDelete, onClose: onClose }));
};
const MemberMenu = ({ memberId, email, role, isDeleted, }) => {
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const history = useHistory();
    return (_jsxs(Menu, { trigger: (props) => _jsx(Button.Icon, { size: "m", ...props, icon: faEllipsisV }), children: [_jsx(Menu.Item, { onClick: () => history.push(addQueryParams(ORDERS_URL, { 'filter[customer]': email })), children: "See orders" }), permissions.customersCanLoginAsCustomer && (_jsx(Menu.Item, { onClick: modalOpener(LoginAsCustomer, { id: memberId }), children: "Login as the customer" })), !isDeleted && permissions.customersMembersUpdate && (_jsx(Menu.Item, { onClick: modalOpener(EditTeamMember, { id: memberId, email, role }), children: "Edit" })), !isDeleted && permissions.customersMembersDelete && (_jsx(Menu.Item, { onClick: modalOpener(DeleteMember, { memberId }), children: "Delete" }))] }));
};
const InvitationMenu = ({ invitationId }) => {
    const { permissions } = useCurrentUserData();
    const resend = useResendInvitation();
    const cancel = useCancelInvitation();
    if (!permissions.customersMembersUpdate || !permissions.customersMembersDelete) {
        return null;
    }
    const isLoading = resend.isLoading || cancel.isLoading;
    return (_jsx(Menu, { trigger: (props) => _jsx(Button.Icon, { size: "m", ...props, icon: faEllipsisV }), children: (onCloseMenu) => (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { disabled: isLoading, loading: resend.isLoading, onClick: () => resend.mutateAsync(invitationId).then(onCloseMenu), children: "Resend invitation" }), _jsx(Menu.Item, { disabled: isLoading, loading: cancel.isLoading, onClick: () => cancel.mutateAsync(invitationId).then(onCloseMenu), children: "Cancel invitation" })] })) }));
};
export const tableConfig = {
    columns: [
        {
            key: 'name',
            name: 'Name',
            width: 'minmax(200px, 2fr)',
            cellRenderer: ({ cellData }) => cellData || '—',
        },
        {
            key: 'email',
            name: 'Email',
            width: 'minmax(200px, 2fr)',
        },
        {
            key: 'role',
            name: 'Role',
            width: 'minmax(104px, 1fr)',
            cellRenderer: ({ cellData }) => cellData.name,
            getCellValue: (cellData) => cellData.value,
        },
        {
            key: 'joined_at',
            name: 'Date joined',
            width: 'minmax(120px, 1fr)',
            cellRenderer: ({ cellData }) => (cellData ? formatDayMonthYear(cellData) : '—'),
            getCellValue: (cellData) => { var _a; return (_a = cellData === null || cellData === void 0 ? void 0 : cellData.getTime()) !== null && _a !== void 0 ? _a : null; },
        },
        {
            key: 'status',
            name: 'Status',
            width: 'minmax(min-content, 144px)',
            cellRenderer: ({ cellData, rowData }) => (_jsxs(_Fragment, { children: [_jsx(Badge, { color: cellData.color, children: cellData.name }), rowData.expires_at && rowData.expires_at.getTime() < new Date().getTime() && (_jsxs("span", { className: "text-greyscale-500", children: ["expired ", formatDateDistance(rowData.expires_at)] }))] })),
            getClassName: () => 'flex items-center gap-1',
            getCellValue: (cellData) => cellData.value,
        },
        {
            key: 'member_id',
            name: '',
            width: '64px',
            cellRenderer: ({ cellData, rowData }) => {
                return rowData.expires_at !== null ? (_jsx(InvitationMenu, { invitationId: cellData })) : (_jsx(MemberMenu, { memberId: cellData, email: rowData.email, role: rowData.role.value, isDeleted: rowData.status.name === 'Deleted' }));
            },
        },
    ],
    getCellClassName: () => 'cursor-default',
    sortable: ['name', 'email', 'joined_at', 'status'],
    searchable: ['name', 'email'],
};
